import React, { Component } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/molecules/SEO';
import Pagination from '../components/organisms/Pagination';
import VendorPreview from '../components/molecules/VendorPreview';
import styled from 'styled-components';
import PreHead from '../components/molecules/PreHead';
import Container from '../components/atoms/Container';
import VendorTableHeader from '../components/molecules/VendorTableHeader';
import VendorQuickLinks from '../components/molecules/VendorQuickLinks';

const Holder = styled.article`
  margin: 10rem 0;
`;

const Inner = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  grid-auto-flow: dense;
  @media(${props => props.theme.breakpoints.lg}) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 3fr;
  }
`;

const Sidebar = styled.article`
  position: relative;
  display: none;
  @media(${props => props.theme.breakpoints.lg}) {
    grid-column: 1/2;
    display: block;
  }
`;

const SidebarInner = styled.div`
    position: sticky;
    top: 10rem;
    left: 0;
`;

const Content = styled.div`
  @media(${props => props.theme.breakpoints.lg}) {
    grid-column: 2/3;
  }
`;

class VendorArchive extends Component {

  render() {

    return (
      <>
        <SEO title="Vendors"/>
        <PreHead text="Vendors" image="hero_00002.jpg"/>
        <Holder>
          <Container>
            <Inner>
              <Content>
                <VendorTableHeader/>
                {this.props.data.allContentfulVendor.nodes.map( node => (
                  <VendorPreview key={node.id} vendor={node}/>
                ) )}
                <Pagination base="vendors" pageContext={this.props.pageContext}/>
              </Content>
              <Sidebar>
                <SidebarInner>
                  <VendorQuickLinks vendors={this.props.data.allContentfulVendor.nodes}/>
                </SidebarInner>
              </Sidebar>
            </Inner>
          </Container>
        </Holder>
      </>
    )
  }
}

export default VendorArchive;

export const vendorArchiveQuery = graphql`
    query vendorArchiveQuery($skip: Int!, $limit: Int!) {
        allContentfulVendor(
            sort: {fields: [title], order: ASC}
            limit: $limit
            skip: $skip
        ) {
            nodes {
                id
                title
                slug
                logo {
                    fluid(maxWidth: 1000) {
                        sizes
                        src
                        srcSet
                        aspectRatio
                    }
                }
                products {
                    id
                    title
                    slug
                }
                training {
                    id
                    title
                    slug
                }
            }
        }
    }
`;
