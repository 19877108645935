import React from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  display: none;
  @media( ${props => props.theme.breakpoints.md} ) {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    margin: 2rem 0;
    grid-gap: 2rem;
  }
`;

function VendorTableHeader() {
  return (
    <Holder>
      <small>Vendor</small>
      <small>Products</small>
      <small>Training</small>
    </Holder>
  )
}

export default VendorTableHeader;
