import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Holder = styled.div`
  display: none;
  @media(${props => props.theme.breakpoints.lg}) {
    display: block;
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      margin: 0;
      line-height: 1.2;
      button {
        ${props => props.theme.typeStyles( -2 )};
        text-decoration: none;
      }
    }
  }
`;

function VendorQuickLinks( { vendors } ) {
  return (
    <Holder>
      <h2 className="h4">Quick links</h2>
      <ul>
        {vendors.map( vendor => (
          <li>
            <button className="link" onClick={() => scrollTo(`#${vendor.slug}`)}>{vendor.title}</button>
          </li>
        ) )}
      </ul>
    </Holder>
  )
}

VendorQuickLinks.propTypes = {
  vendors: PropTypes.array.isRequired,
};

export default VendorQuickLinks;
