import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import VendorLogo from '../atoms/VendorLogo';

const Holder = styled.div`
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 2rem 0;
  padding-bottom: 2rem;
  border-bottom: 1px dotted ${props => props.theme.colours.darkGrey};
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 2fr 2fr;
  }
`;


const Cell = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      margin: 0;
      a {
        text-decoration: none;
        position: relative;
        :after {
          content: '';
          opacity: 0;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          background-color: ${props => props.theme.colours.green};
          position: absolute;
          top: 40%;
          left: -1rem;
          transition: opacity 0.25s;
        }
        &:hover:after { opacity: 1; }
      }
    }
  }
`;

const CellHeading = styled.small`
  font-weight: bold;
  @media( ${props => props.theme.breakpoints.md} ) {
    display: none;
  }
`;

class VendorPreview extends Component {
  render() {
    const { vendor } = this.props;
    return (
      <Holder id={vendor.slug} className="jump-target">
        <Inner>
          <Cell>
            <VendorLogo base={vendor.products ? 'vendor' : 'vendor-training'} vendor={vendor}/>
          </Cell>
          <Cell>
            <CellHeading>Products</CellHeading>
            <ul>
              {vendor.products && vendor.products.map( product => (
                <li key={product.id}>
                  <small>
                    <Link to={'/product/' + product.slug}>{product.title}</Link>
                  </small>
                </li>
              ) )}
            </ul>
          </Cell>
          {vendor.training && <Cell>
            <CellHeading>Training</CellHeading>
            <ul>
              {vendor.training.map( training => (
                <li key={training.id}>
                  <small>
                    <Link to={'/training/' + training.slug}>{training.title}</Link>
                  </small>
                </li>
              ) )}
            </ul>
          </Cell>}
        </Inner>
      </Holder>
    )
  }
}

VendorPreview.propTypes = {
  vendor: PropTypes.shape( {
    title: PropTypes.string,
    slug: PropTypes.string,
    logo: PropTypes.object,
    products: PropTypes.arrayOf(
      PropTypes.shape( {
        id: PropTypes.string,
        title: PropTypes.string,
        slug: PropTypes.string,
      } )
    ),
    training: PropTypes.arrayOf(
      PropTypes.shape( {
        id: PropTypes.string,
        title: PropTypes.string,
        slug: PropTypes.string,
      } )
    ),
  } ).isRequired,
};

export default VendorPreview;
